import React from "react";
import Footer from "./Footer";
import Header from "./Header";
import "./privacy.css";

const Privacy = () => {
  return (
    <>
      <head>
        <title>Privacy - CargoTrack | Relocation Experts in KSA</title>
      </head>
      <div className="w-full h-full">
        <Header />

        <body className="c5 doc-content md:pt-40 pt-20 lg:px-[100px] sm:px-[30px] px-[16px] mb-4">
          <h1 className="c3" id="h.hcatct82s79q">
            <span className="c4">Privacy Policy for CARGO TRACK</span>
          </h1>
          <p className="c1">
            <span className="c2">
              At cargotrack.co, accessible from cargotrack.co, one of our main
              priorities is the privacy of our visitors. This Privacy Policy
              document contains types of information that is collected and
              recorded by cargotrack.co and how we use it.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              If you have additional questions or require more information about
              our Privacy Policy, do not hesitate to contact us.
            </span>
          </p>
          <p className="c1">
            <span className="c9">
              This Privacy Policy applies only to our online activities and is
              valid for visitors to our website with regards to the information
              that they shared and/or collect in cargotrack.co. This policy is
              not applicable to any information collected offline or via
              channels other than this website. Our Privacy Policy was created
              with the help of the{" "}
            </span>
            <span className="c9">
              <a
                className="c6"
                href="https://www.google.com/url?q=https://www.privacypolicygenerator.info/&amp;sa=D&amp;source=editors&amp;ust=1671174878582635&amp;usg=AOvVaw0gLiC90cEo1pi1Dda7yHEX"
              >
                Free Privacy Policy Generator
              </a>
            </span>
            <span className="c2">.</span>
          </p>
          <h2 className="c3" id="h.zar7uy7v3cso">
            <span className="c0">Consent</span>
          </h2>
          <p className="c1">
            <span className="c2">
              By using our website, you hereby consent to our Privacy Policy and
              agree to its terms.
            </span>
          </p>
          <h2 className="c3" id="h.222e4bv219r0">
            <span className="c0">Information we collect</span>
          </h2>
          <p className="c1">
            <span className="c2">
              The personal information that you are asked to provide, and the
              reasons why you are asked to provide it, will be made clear to you
              at the point we ask you to provide your personal information.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              If you contact us directly, we may receive additional information
              about you such as your name, email address, phone number, the
              contents of the message and/or attachments you may send us, and
              any other information you may choose to provide.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              When you register for an Account, we may ask for your contact
              information, including items such as name, company name, address,
              email address, and telephone number.
            </span>
          </p>
          <h2 className="c3" id="h.s44jzccxcapd">
            <span className="c0">How we use your information</span>
          </h2>
          <p className="c1">
            <span className="c2">
              We use the information we collect in various ways, including to:
            </span>
          </p>
          <ul className="c7 lst-kix_nv8tu3dmspa1-0 start">
            <li className="c8 li-bullet-0">
              <span className="c2">
                Provide, operate, and maintain our website
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c2">
                Improve, personalize, and expand our website
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c2">
                Understand and analyze how you use our website
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c2">
                Develop new products, services, features, and functionality
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c2">
                Communicate with you, either directly or through one of our
                partners, including for customer service, to provide you with
                updates and other information relating to the website, and for
                marketing and promotional purposes
              </span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c2">Send you emails</span>
            </li>
            <li className="c8 li-bullet-0">
              <span className="c2">Find and prevent fraud</span>
            </li>
          </ul>
          <h2 className="c3" id="h.9c8xu92kbaja">
            <span className="c0">Log Files</span>
          </h2>
          <p className="c1">
            <span className="c2">
              cargotrack.co follows a standard procedure of using log files.
              These files log visitors when they visit websites. All hosting
              companies do this and a part of hosting services&#39; analytics.
              The information collected by log files include internet protocol
              (IP) addresses, browser type, Internet Service Provider (ISP),
              date and time stamp, referring/exit pages, and possibly the number
              of clicks. These are not linked to any information that is
              personally identifiable. The purpose of the information is for
              analyzing trends, administering the site, tracking users&#39;
              movement on the website, and gathering demographic information.
            </span>
          </p>
          <h2 className="c3" id="h.566yx3qp1zss">
            <span className="c0">Cookies and Web Beacons</span>
          </h2>
          <p className="c1">
            <span className="c2">
              Like any other website, cargotrack.co uses &#39;cookies&#39;.
              These cookies are used to store information including
              visitors&#39; preferences, and the pages on the website that the
              visitor accessed or visited. The information is used to optimize
              the users&#39; experience by customizing our web page content
              based on visitors&#39; browser type and/or other information.
            </span>
          </p>
          <h2 className="c3" id="h.e1k1wzqgrhjq">
            <span className="c0">Advertising Partners Privacy Policies</span>
          </h2>
          <p className="c1">
            <span className="c2">
              You may consult this list to find the Privacy Policy for each of
              the advertising partners of cargotrack.co.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              Third-party ad servers or ad networks uses technologies like
              cookies, JavaScript, or Web Beacons that are used in their
              respective advertisements and links that appear on cargotrack.co,
              which are sent directly to users&#39; browser. They automatically
              receive your IP address when this occurs. These technologies are
              used to measure the effectiveness of their advertising campaigns
              and/or to personalize the advertising content that you see on
              websites that you visit.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              Note that cargotrack.co has no access to or control over these
              cookies that are used by third-party advertisers.
            </span>
          </p>
          <h2 className="c3" id="h.gkgt5hy2hhgn">
            <span className="c0">Third Party Privacy Policies</span>
          </h2>
          <p className="c1">
            <span className="c2">
              cargotrack.co&#39;s Privacy Policy does not apply to other
              advertisers or websites. Thus, we are advising you to consult the
              respective Privacy Policies of these third-party ad servers for
              more detailed information. It may include their practices and
              instructions about how to opt-out of certain options.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              You can choose to disable cookies through your individual browser
              options. To know more detailed information about cookie management
              with specific web browsers, it can be found at the browsers&#39;
              respective websites.
            </span>
          </p>
          <h2 className="c3" id="h.kz4ftyymv30q">
            <span className="c0">
              CCPA Privacy Rights (Do Not Sell My Personal Information)
            </span>
          </h2>
          <p className="c1">
            <span className="c2">
              Under the CCPA, among other rights, California consumers have the
              right to:
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              Request that a business that collects a consumer&#39;s personal
              data disclose the categories and specific pieces of personal data
              that a business has collected about consumers.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              Request that a business delete any personal data about the
              consumer that a business has collected.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              Request that a business that sells a consumer&#39;s personal data,
              not sell the consumer&#39;s personal data.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </span>
          </p>
          <h2 className="c3" id="h.6fxoj7oeks8c">
            <span className="c0">GDPR Data Protection Rights</span>
          </h2>
          <p className="c1">
            <span className="c2">
              We would like to make sure you are fully aware of all of your data
              protection rights. Every user is entitled to the following:
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              The right to access &ndash; You have the right to request copies
              of your personal data. We may charge you a small fee for this
              service.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              The right to rectification &ndash; You have the right to request
              that we correct any information you believe is inaccurate. You
              also have the right to request that we complete the information
              you believe is incomplete.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              The right to erasure &ndash; You have the right to request that we
              erase your personal data, under certain conditions.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              The right to restrict processing &ndash; You have the right to
              request that we restrict the processing of your personal data,
              under certain conditions.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              The right to object to processing &ndash; You have the right to
              object to our processing of your personal data, under certain
              conditions.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              The right to data portability &ndash; You have the right to
              request that we transfer the data that we have collected to
              another organization, or directly to you, under certain
              conditions.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              If you make a request, we have one month to respond to you. If you
              would like to exercise any of these rights, please contact us.
            </span>
          </p>
          <h2 className="c3" id="h.o2i61r7xnhra">
            <span className="c0">Children&#39;s Information</span>
          </h2>
          <p className="c1">
            <span className="c2">
              Another part of our priority is adding protection for children
              while using the internet. We encourage parents and guardians to
              observe, participate in, and/or monitor and guide their online
              activity.
            </span>
          </p>
          <p className="c1">
            <span className="c2">
              cargotrack.co does not knowingly collect any Personal Identifiable
              Information from children under the age of 13. If you think that
              your child provided this kind of information on our website, we
              strongly encourage you to contact us immediately and we will do
              our best efforts to promptly remove such information from our
              records.
            </span>
          </p>
        </body>
        <Footer />
      </div>
      ;
    </>
  );
};

export default Privacy;
