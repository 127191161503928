import icon from "../../assets/images/icons/Group.svg";

const Heading = () => {
  return (
    <span className=" flex flex-row gap-[20px] items-center mr-[70px]">
      <img src={icon} className="object-contain h-[45px]" alt="" />
      <p className="font-vetica text-[#03719A]  xl:text-5xl lg:text-5xl xl:leading-none lg:leading-none leading-none text-[36px] lg:shrink-0 ld:w-max ">
        Completed{" "}
        <span className="font-vetica text-[#00AEEF]">international moves</span>
      </p>
    </span>
  );
};

export default Heading;
