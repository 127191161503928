const defaultClass =
  "px-2 py-2 w-[40px] h-[40px] flex items-center justify-center aspect-square rounded-full bg-[#002A39] absolute z-20 top-1/2 -translate-y-1/2 max-sm:opacity-60";

export function NextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={`${defaultClass} ${className} `}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg
        width="34"
        height="20"
        viewBox="0 0 34 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 8.75C1.30964 8.75 0.75 9.30964 0.75 10C0.75 10.6904 1.30964 11.25 2 11.25V8.75ZM32.8839 10.8839C33.372 10.3957 33.372 9.60427 32.8839 9.11612L24.9289 1.16117C24.4408 0.67301 23.6493 0.67301 23.1612 1.16117C22.673 1.64932 22.673 2.44078 23.1612 2.92893L30.2322 10L23.1612 17.0711C22.673 17.5592 22.673 18.3507 23.1612 18.8388C23.6493 19.327 24.4408 19.327 24.9289 18.8388L32.8839 10.8839ZM2 11.25H32V8.75H2V11.25Z"
          fill="#00AEEF"
        />
      </svg>
    </button>
  );
}

export function PrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <button
      className={`${defaultClass} ${className}`}
      style={{ ...style }}
      onClick={onClick}
    >
      <svg
        width="34"
        height="20"
        viewBox="0 0 34 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2 8.75C1.30964 8.75 0.75 9.30964 0.75 10C0.75 10.6904 1.30964 11.25 2 11.25V8.75ZM32.8839 10.8839C33.372 10.3957 33.372 9.60427 32.8839 9.11612L24.9289 1.16117C24.4408 0.67301 23.6493 0.67301 23.1612 1.16117C22.673 1.64932 22.673 2.44078 23.1612 2.92893L30.2322 10L23.1612 17.0711C22.673 17.5592 22.673 18.3507 23.1612 18.8388C23.6493 19.327 24.4408 19.327 24.9289 18.8388L32.8839 10.8839ZM2 11.25H32V8.75H2V11.25Z"
          fill="#00AEEF"
        />
      </svg>
    </button>
  );
}
