export const internationalMovesQuery = `query {
  internationalMoves(id: "3KnbW3a7rRXVEMMsZGt4zA") {
    title
    movesCollection(limit: 70) {
      items {
        from
        to
        image {
          url
        }
        weight
        volume
      }
    }
  }
}`;
export const reviewCollectionQuery = `query {
  reviewCollection(order:order_ASC) {
    items {
      name
      location
      content
    }
  }
}`;
