import { LazyLoadImage } from "react-lazy-load-image-component";
import img from "../../assets/images/gallery/Boxes.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import Button from "./Button";
import { NextArrow, PrevArrow } from "./SliderBtns";

import "swiper/css";
import { useRef, useState } from "react";
import { Autoplay } from "swiper/modules";

const Slider = ({ data }) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const swiperRef = useRef();
  return (
    <div className="w-full  relative z-0">
      {activeIndex > 0 && (
        <PrevArrow
          className="left-0 -translate-x-1/2 rotate-180"
          onClick={() => swiperRef.current?.slidePrev()}
        />
      )}
      {data.data.internationalMoves.movesCollection.items.length > 3 && (
        <NextArrow
          className="right-0 translate-x-1/2 sm:mr-[30px] lg:mr-[100px]"
          onClick={() => swiperRef.current?.slideNext()}
        />
      )}
      <Swiper
        slidesPerView={1}
        spaceBetween={20}
        modules={[Autoplay]}
        onRealIndexChange={(element) => setActiveIndex(element.activeIndex)}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
        breakpoints={{
          320: {
            slidesPerView: 1,
            spaceBetween: 20,
          },

          680: {
            slidesPerView: 3,
            spaceBetween: 30,
          },

          1480: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
        }}
        autoplay={{
          delay: 2500,
        }}
        loop
        className="mySwiper relative"
      >
        {data.data.internationalMoves.movesCollection.items.map((item, idx) => (
          <SwiperSlide key={idx}>
            <div className="flex flex-col bg-[#D0EAF4] rounded-[20px] overflow-hidden ">
              <LazyLoadImage
                src={item.image.url}
                alt={item.from}
                effect="blur"
                className="object-cover select-none w-full aspect-square"
              />
              <div className="w-full flex flex-col px-4  py-4">
                <div className="flex items-center justify-center  gap-2">
                  <Button>Weight: {item.weight} Kg</Button>
                  <Button>Volume: {item.volume} m³</Button>
                </div>
                <p className="font-vetica text-xl text-black mt-4">
                  {item.from} — {item.to}
                </p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Slider;
