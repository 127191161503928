import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import Logo from '../assets/image.png'

const WhatsappIcon = () => {
    return (
        <FloatingWhatsApp phoneNumber='+966590967593' accountName={'Cargotrack'} avatar={Logo} />
    )
}

export default WhatsappIcon