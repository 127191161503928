import React from "react";
import Header from "./Header";
import Footer from "./Footer";
import FooterBanner from "./FooterBanner";
import { Link, useParams } from "react-router-dom";
import {
  DataCenterImg,
  GeorgeServiceImg,
  HouseholdImg,
  MobilityServiceImage,
  PackingImg,
  RoadTransportation,
  StorageServiceImg,
  VehicleRelocationImg,
  PilgrimBaggageImg,
} from "../assets/servicesImages";

function SingleService({ type, name, see_all_btn }) {
  const id = useParams().id;

  const services = [
    {
      id: 1,
      heading: "Household Goods",
      heading2: "Relocations",
      content:
        "When there is a complex and time-consuming procedure of home relocation in front of you, planning it in the right manner can assure you a secure and organised shifting. Creating ahouse relocation plan in a haphazard manner will compromise your belongings safety. As part of its home relocation service, Cargotrack disassembles furniture and electronic appliances, unmounts ACs and TVs, and packages fragile kitchen supplies such as crockery, cutlery, and chinaware using the best packaging materials. Our home relocation services don’t come to a conclusion until your household items are delivered to their required destination on time with complete safety.There are many reasons because of which people need to relocate their homes. They would want to move their household belongings safely. Hiring home relocation services would ensure an easier and smooth transition process.",
      description:
        "The amount of work involved in Home relocation service is extensive and can prove stressful. Due to this, many people like to hire home shifting services from reputed movers and packers. Hiring a professional packing company like Cargotrack to relocate your home is a smart choice. Cargotrack has a long year of experience and expertise in carrying out domestic moves within the city and intercity.We utilise modern and advanced techniques to perform relocation tasks with great care. Our trustworthy packers and movers serve both local as well as long distant moves.",
      picture: HouseholdImg,
    },
    {
      id: 2,
      heading: "Office Relocation & ",
      heading2: "Data Centre Migration",
      content:
        "Cargotrack Office Move is a well-established service providing you with the benefit of our experience in this demanding field. We understand the need for planning, communication and close cooperation of your office move with all concerned parties. Timing is everything, and getting your office move right is critical to your business success With our skill and expertise, you can be assured of a smooth, efficient and well-planned execution of your office move We will design a detailed schedule of your move, tailored to your business requirements.Our team of skilled packers and handlers headed by experienced supervisors will be assigned. Depending on the size and complexity of your move, your coordinator can work with you to develop the best coding system for your requirements by packing your items and labelling them with color coding to identify their location and department.",
      description:
        "The amount of work involved in Home relocation service is extensive and can prove stressful. Due to this, many people like to hire home shifting services from reputed movers and packers. Hiring a professional packing company like Cargotrack to relocate your home is a smart choice. Cargotrack has a long year of experience and expertise in carrying out domestic moves within the city and intercity.We utilise modern and advanced techniques to perform relocation tasks with great care. Our trustworthy packers and movers serve both local as well as long distant moves.",
      picture: DataCenterImg,
    },
    {
      id: 3,
      heading: "Mobility",
      heading2: "Services",
      content:
        "Our open workspace platform integrates with industry-leading solutions. It uses modern digital workplace with advanced security capabilities. Cargotrack measures and optimises the employee digital experience to improve collaboration, productivity, and culture. We predictively and proactively secure and fix things before they break.  Through our services, we provide management and support for millions of devices, virtual desktops, and provide robust security and resilience.",
      description: "",
      picture: MobilityServiceImage,
    },
    {
      id: 4,
      heading: "Road ",
      heading2: "Transportations",
      content:
        "At Cargotrack, we use innovative technologies and have excellent personnel to offer you bespoke transport solutions whether you require FCL or LCL. You can take advantage of our fast transit times, reliable capacities, wide range of products, and unrivaled service quality. Our fleet of trucks ensures your daily transport needs are met quickly and cost-effectively. As a member of leading corporations with experienced customs agents, we provide the level of security you need for your economic success throughout Europe.            ",
      description: "",
      picture: RoadTransportation,
    },
    {
      id: 5,
      heading: "Packing",
      heading2: "& Crating",
      content:
        "Once you decide which Packers and Movers service you need and you confirm your moving with us, your work is done, we will do the rest.With our  expertly trained professionals, we hold the highest standards of security among Packers and movers to ensure your belongings are safe and secure.  As an Eco Friendly packers and movers company, we are committed to using only 100% recyclable materials, energy-saving facilities, and engine-efficient fleet systems that emit less pollution.  Our wide range of moving options and customized moving services give you the convenience of having just what you need.We will reach your place at your time of convenience to pack all the items. Our packers and movers are well trained for this type of work, you can relax while they pack.All the packed items are then loaded into the transportation vehicle. Our Vehicles are well equipped to keep your items safe during transportation. Our packers and movers have done a good job so you can relax. ",
      description: "",
      picture: PackingImg,
    },
    {
      id: 6,
      heading: "Groupage ",
      heading2: "Services",
      content:
        "Groupage freight is a shipping method offered by Cargotrack that combines several smaller shipments into one full container when goods are transported in bundles to form a consolidated shipment. This shipping method is suitable for single pallets. For a larger pallet quantity, partial load shipment is generally the cheaper option. Groupage involves collecting general cargo from various shippers and combining it in the forwarder's warehouse. After delivery, they are separated again in the corresponding warehouses. Groupage freight is a widely used procedure in logistics because mixing the different shipments is particularly economical.",
      description: "",
      picture: GeorgeServiceImg,
    },

    {
      id: 7,
      heading: "Storage ",
      heading2: "Services",
      content:
        "With our assured responsibility and care,it goes without saying that our self -storage units will keep your belongings with atmost safe,clean and dry space.We have included internal and external security for your belongings protection.Choosing Cargotrack will offer you storage space to your individual requirements, storage advisors to guide and to give estimation over your storage ,reliable charges based on the space and time you used,fully protected storage space.In your absence ,an authorised representative of you can access your storage.",
      description: "",
      picture: StorageServiceImg,
    },
    {
      id: 8,
      heading: "Vehicle ",
      heading2: "Relocations",
      content:
        "Cargotrack is committed to helping you find the most reliable and affordable vehicle shipping services in your area. We offer a wide range of relocation and transport-related services, and our vehicle transport is one of our most popular services. With Cargotrack, you can be sure you're getting the best possible service at the best possible price.To provide you with the most straightforward quality automotive shipping service at your doorstep. Saudi Arabia's vehicle transport service has created a range of top moving companies and auto transport companies. You can choose the simplest service provider based on your real needs with the help of vehicle relocation services. Consider moving your vehicle and entire home goods from Saudi Arabia to another city.",
      description: "",
      picture: VehicleRelocationImg,
    },
    {
      id: 9,
      heading: "Pilgrim Baggage",
      heading2: "Handling",
      content: (
        <p className="lg:text-[22px] text-lg text-justify text-[#03719A]">
          Pilgrim baggage handling services play a crucial role in ensuring a
          hassle-free and smooth pilgrimage experience for those visiting the
          holy cities of Mecca and Medina in Saudi Arabia. Our services
          specialize in collecting pilgrims' baggage directly from them and
          handling all customs formalities at Jeddah, allowing them to focus on
          their religious obligations. <br/> <br/>
          Our team is dedicated to ensuring the safe
          and convenient delivery of pilgrims' belongings to their final
          destinations world wide especially to Asian countries. We provide
          door-to-door delivery services, ensuring that their possessions reach
          their home country securely and efficiently.
        </p>
      ),
      description:
        "To minimize waiting time and optimize convenience, we set up dedicated pick-up counters at hotels with large Umrah/Hajj groups. Our team also goes above and beyond by repacking the luggage to meet shipping standards and ensure that it is ready for freight. With our dependable baggage handling services, pilgrims can relax and focus on their spiritual journey, knowing that their belongings are in capable and trustworthy hands.",
      picture: PilgrimBaggageImg,
    },
  ];

  const title =
    services.filter((service) => service.id == id)[0].heading +
    services.filter((service) => service.id == id)[0].heading2;

  return (
    <div>
      <head>
        <title>{title} | CargoTrack | Movers in KSA</title>
      </head>
      {/* begin */}
      <Header />
      <div className="lg:px-[100px] sm:px-[30px] px-[16px] md:pt-40 pt-20 ">
        {services
          .filter((service) => service.id == id)
          .map((service) => (
            <>
              <div
                key={service.id}
                className="grid lg:grid-cols-2  w-full lg:mt-4 mt-5 gap-5"
                style={{
                  height: "auto",
                  marginBottom: "5%",
                }}
              >
                <div className="w-full">
                  <div>
                    <div className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] leading-[32px] ">
                      {service.heading}{" "}
                      <span className="font-vetica text-[#00AEEF]">
                        {service.heading2}
                      </span>
                    </div>
                    <div className="mt-4 lg:text-[22px] text-lg text-justify text-[#03719A]">
                      {service.content}
                    </div>

                    {!service.description && (
                      <Link to="/contact-us">
                        <button className="cursor-pointer bg-[#00AEEF] lg:px-4 px-3 py-2  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white lg:flex hidden mt-4">
                          {"Contact Us"}
                        </button>
                      </Link>
                    )}
                    <Link to="/contact-us">
                      <button className="cursor-pointer bg-[#00AEEF] lg:px-4 px-3 py-2  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white mt-3 lg:hidden flex">
                        {"Contact Us"}
                      </button>
                    </Link>
                  </div>
                </div>
                <div
                  id=""
                  className="w-full flex items-center justify-center mt-3"
                >
                  <img
                    src={service.picture}
                    alt=""
                    className="object-cover w-full"
                    loading="lazy"
                  />
                </div>
              </div>

              {service.description && (
                <div
                  className="xsection row p-0 align-items-stretch dark topmargin-sm lg:flex hidden"
                  style={{
                    height: "auto",
                    marginBottom: "5%",
                  }}
                >
                  <div
                    className="col-lg-12"
                    style={{
                      // display: 'flex',
                      // flexDirection: 'column',
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ width: "80%" }}>
                      <div className="banner_font  color-primary-medium">
                        {""}{" "}
                      </div>
                      <div className="banner_font color-secondary">{""}</div>
                      <div className="banner_font color-primary-medium"></div>
                      <div className="lg:text-[22px] text-lg text-justify text-[#03719A] ">
                        {service.description}
                      </div>
                      {service.description && (
                        <Link to="/contact-us">
                          <button className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[183px] lg:h-[63px] w-[88px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white mt-4 font-medium hover:bg-[#03719A] duration-300">
                            {"Contact Us"}
                          </button>
                        </Link>
                      )}
                      {/* <button className='btn btnprimary  mt-4'>{button.text}</button> */}
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}

        <FooterBanner />
      </div>
      <Footer />
      {/* end */}
    </div>
  );
}

export default SingleService;
