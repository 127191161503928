import { accessToken, space } from "./client";
import { internationalMovesQuery, reviewCollectionQuery } from "./queries";

const URL = `https://graphql.contentful.com/content/v1/spaces/${space}`;

export async function getDataFromContentful({ type }) {
  const useQuery = {
    internationalMoves: internationalMovesQuery,
    reviews: reviewCollectionQuery,
  };
  const query = useQuery[type];
  const res = await fetch(URL, {
    method: "POST",
    headers: {
      "content-type": "application/json",
      authorization: `Bearer ${accessToken}`,
    },
    body: JSON.stringify({ query: query }),
    cache: "no-store",
  });

  if (!res.ok) return;

  return res.json();
}
