import React, { useState } from "react";
import logo from "../assets/images/logo/logo.png";
import { Link } from "react-router-dom";

function Header() {
  const [isToggle, setIsToggle] = useState(false);

  return (
    <div className="h-[1px] w-full relative z-50">
      {/* laptop navbar start */}
      <div className="absolute top-0 h-[85px] w-full z-50 mt-[40px] px-[100px] lg:flex hidden overflow-hidden justify-between items-center mb-[120px] heeedeeeeeeeeeeee">
        <Link to="/">
          <img
            src={logo}
            alt="logo"
            className="object-contain h-[80px] z-50 cursor-pointer"
          />
        </Link>

        <div className="flex h-full items-center justify-end">
          <ul className="flex gap-[40px] text-[#03719A] text-[20px]">

            <Link to="/" className="">
              <div>Home</div>
            </Link>
            <Link to="/about" className="">
              <div>About us</div>
            </Link>

            <Link to="/services" className="">
              <div>Services</div>
            </Link>
            <Link to="/contact-us" className="">
              <div>Contact us</div>
            </Link>

            <Link to="/gallery" className="">
              <div>Gallery</div>
            </Link>
          </ul>
        </div>
      </div>
      {/* lapTopNavbar ends */}

      {/* mobile navbar start */}

      <div className="h-[40px] w-full z-50 mt-[40px] px-[30px] flex lg:hidden overflow-hidden justify-between items-center">
        <Link to="/" className="flex h-full">
          <img
            src={logo}
            alt="logo"
            className="object-contain h-[70px] cursor-pointer"
          />
        </Link>
        <div
          onClick={() => setIsToggle(!isToggle)}
          className={`h-8 w-8 bg-[#00AEEF] rounded-[3px] flex flex-col items-center relative cursor-pointer ${
            isToggle ? "justify-center" : "justify-evenly"
          } py-1 z-[60]`}
        >
          <span
            className={`h-[2px] rounded-lg w-[60%] bg-slate-800 flex duration-300 ${
              isToggle && "rotate-45 absolute"
            } `}
          />
          <span
            className={`h-[2px] rounded-lg w-[60%] bg-slate-800 duration-300 ${
              isToggle ? "hidden" : "flex"
            }`}
          />
          <span
            className={`h-[2px] rounded-lg w-[60%] bg-slate-800 flex duration-300 ${
              isToggle && "-rotate-45 absolute"
            }`}
          />
        </div>

        {/* navLinks */}
        <div
          className={`absolute right-[30px] top-[90px] bg-[#002A39] p-[24px] rounded-l-xl rounded-br-xl text-white text-[20px] gap-4 flex flex-col min-w-[150px] ${
            isToggle ? "translate-y-0" : "-translate-y-[1000px]"
          } transition-transform duration-300 ease-out`}
        >
          <Link to="/" className="" onClick={() => setIsToggle(false)}>
            <div>Home</div>
          </Link>

          <Link to="/about" className="" onClick={() => setIsToggle(false)}>
            <div>About Us</div>
          </Link>

          <Link to="/services" className="" onClick={() => setIsToggle(false)}>
            <div>Services</div>
          </Link>

          <Link to="/gallery" className="" onClick={() => setIsToggle(false)}>
            <div>Gallery</div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Header;
