import React from "react";
import Header from "../Components/Header";
import Topbar from "../Components/Topbar";
// import TwoColumOne from '../Components/TwoColumOne'
import Footer from "../Components/Footer";
import FooterBanner from "../Components/FooterBanner";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

import { imageList } from "../assets/images/gallery";

const chunkSize = Math.floor(imageList.length / 3);

const output = [];
let tempArray = [];

for (let i = 0; i < imageList.length; i++) {
  if (i > 0 && i % chunkSize === 0) {
    output.push(tempArray);
    tempArray = [];
  }
  tempArray.push(imageList[i]);
}

if (tempArray.length > 0) {
  output.push(tempArray);
}


const h1 = "600px";
const w1 = "600px";

const h2 = "290px";
const w2 = "300px";

function Gallery() {
  return (
    <div>
      <head>
        <title>Gallery - CargoTrack | Relocation Experts in KSA</title>
      </head>
      {/* begin */}
      <div>
        <Topbar />
        <Header />

        <div
          style={{ height: "50px" }}
          className="md:pt-40 pt-20 lg:px-[100px] sm:px-[30px] px-[16px]"
        />

        <GalleryWeb />

        <div style={{ height: "50px" }} />

        <FooterBanner />
        <Footer />
      </div>
      {/* end */}
    </div>
  );
}

export default Gallery;

const GalleryWeb = () => {
  return (
    <div className="max-w-[1920px] mx-auto grid md:grid-cols-3 sm:grid-cols-2 gap-6 w-full  lg:px-[100px] sm:px-[30px] px-[16px]">
      {/*  */}
      {output.map((itemArray, itemIdx) => (
        <div key={itemIdx} className="w-full h-full grid gap-4 ">
          {itemArray.map((item, i) => (
            <LazyLoadImage
              key={i}
              src={item}
              alt=""
              effect="blur"
              loading="lazy"

              className="w-full object-cover h-full  rounded-md overflow-hidden"
            />
          ))}
        </div>
      ))}
    </div>
  );
};

// const GalleryMobile = () => {
//   return (
//     <div className="px-[30px] w-full sm:hidden grid gap-2">
//       {/*  */}
//       <div className="grid gap-2 w-full">
//         <div className=" grid ">
//           <div style={{ height: "auto" }} className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery12}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="grid grid-rows-2 gap-2">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery24}
//               alt=""
//               style={{}}
//             />
//           </div>
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery23}
//               alt=""
//               style={{}}
//             />
//           </div>
//         </div>
//       </div>
//       {/*  */}
//       <div className="grid gap-2 w-full">
//         <div className=" grid ">
//           <div style={{ height: "auto" }} className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery22}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="grid grid-rows-2 gap-2">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery21}
//               alt=""
//               style={{}}
//             />
//           </div>
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery20}
//               alt=""
//               style={{}}
//             />
//           </div>
//         </div>
//       </div>
//       {/*  */}
//       <div className="grid gap-2 w-full">
//         <div className=" grid ">
//           <div style={{ height: "auto" }} className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery19}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="grid grid-rows-2 gap-2">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery18}
//               alt=""
//               style={{}}
//             />
//           </div>
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery17}
//               alt=""
//               style={{}}
//             />
//           </div>
//         </div>
//       </div>
//       {/*  */}
//       <div className="grid gap-2 w-full">
//         <div className=" grid ">
//           <div style={{ height: "auto" }} className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery16}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="grid grid-rows-2 gap-2">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery15}
//               alt=""
//               style={{}}
//             />
//           </div>
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery14}
//               alt=""
//               style={{}}
//             />
//           </div>
//         </div>
//       </div>
//       {/*  */}
//       <div className="grid gap-2 w-full">
//         <div className=" grid ">
//           <div style={{ height: "auto" }} className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery14}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="grid grid-rows-2 gap-2">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery13}
//               alt=""
//               style={{}}
//             />
//           </div>
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery12}
//               alt=""
//               style={{}}
//             />
//           </div>
//         </div>
//       </div>
//       {/*  */}
//       {/*  */}
//       <div className="grid gap-2 w-full">
//         <div className=" grid ">
//           <div style={{ height: "auto" }} className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery11}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="grid grid-rows-2 gap-2">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery10}
//               alt=""
//               style={{}}
//             />
//           </div>
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery9}
//               alt=""
//               style={{}}
//             />
//           </div>
//         </div>
//       </div>
//       {/*  */}
//       <div className="grid gap-2 w-full">
//         <div className=" grid ">
//           <div style={{ height: "auto" }} className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery1}
//               alt=""
//             />
//           </div>
//         </div>
//         <div className="grid grid-rows-2 gap-2">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery2}
//               alt=""
//               style={{}}
//             />
//           </div>
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery3}
//               alt=""
//               style={{}}
//             />
//           </div>
//         </div>
//       </div>
//       {/*  */}
//       <div className="grid  gap-2 w-full">
//         <div className="grid grid-rows-2 gap-2">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery5}
//               alt=""
//               style={{}}
//             />
//           </div>
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery6}
//               alt=""
//               style={{}}
//             />
//           </div>
//         </div>
//         <div className="grid">
//           <div className="rounded overflow-hidden">
//             <img
//               className="object-cover h-full w-full rounded"
//               src={gallery7}
//               alt=""
//             />
//           </div>
//         </div>
//       </div>
//       {/*  */}
//     </div>
//   );
// };
