import { useEffect, useState } from "react";
import { getDataFromContentful } from "../../libs/contentful/data";
import Heading from "./Heading";
import Slider from "./Slider";

const CompletedInternationalMoves = () => {
  const [data, setData] = useState();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    const d = await getDataFromContentful({ type: "internationalMoves" });

    setData(d);
  }, []);

  return (
    <section className="sm:pl-[30px] pl-[16px] lg:pl-[100px] max-sm:pr-[16px] sm:py-[50px] py-[26px] w-full flex-col flex gap-8">
      <Heading />
      {data && <Slider data={data} />}
    </section>
  );
};

export default CompletedInternationalMoves;
