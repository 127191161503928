import React from "react";
import Caurousel from "./Caurousel";
import Footer from "./Footer";
import Header from "./Header";
// import RibbonBanner from './RibbonBanner'
import RibbonBannerMembership from "./RibbonBannerMembership";
import Services from "./Services";
import Topbar from "./Topbar";
import QuestionBanner from "./QuestionBanner";
import FooterBanner from "./FooterBanner";
import { useEffect } from "react";
import SmallDetail from "./SmallDetail";
import CompletedInternationalMoves from "./CompletedInternationalMoves";

function HomePage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <head>
        <title>Relocation Experts in KSA | CargoTrack</title>
      </head>
      {/* begin */}
      <div className="flex w-full flex-col">
        <Topbar />
        <Header />
        <Caurousel />
          <RibbonBannerMembership />
        <div className="mt-4 flex w-full lg:px-[100px] sm:px-[30px] px-[16px]">
          <SmallDetail />
        </div>
        <QuestionBanner />
        <div className="sm:px-[30px] px-[16px] lg:px-[100px]">
          <Services
            type=""
            name="Our&nbsp;Services"
            see_all_btn={true}
            page={"home"}
          />
        </div>


        <CompletedInternationalMoves />

        <div className="sm:px-[30px] px-[16px] lg:px-[100px]">
          <Services
            type="customer"
            name="Customer&nbsp;Feedback"
            see_all_btn={false}
          />
          <FooterBanner />
        </div>
        <Footer />
      </div>
    </>
  );
}

export default HomePage;
