import HomePage from "./Components/HomePage";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Gallery from "./Pages/Gallery";
import GetAQuote from "./Pages/GetAQuote";
import ContactUs from "./Pages/ContactUs";
import CountryGuide from "./Pages/CountryGuide";
import Service from "./Components/SingleService";
import WhatsappIcon from "./Components/WhatsappIcon";
import ScrollTop from "./ScrollToTop";
import Privacy from "./Components/Privacy";
import ReactGA from "react-ga";
import { useEffect } from "react";
import LoadCalculator from "./Pages/LoadCalculator";

function App() {
  

  return (
    <>
      <div className="max-w-[2560px] overflow-hidden w-full mx-auto">
        <Router>
          <ScrollTop />
          <Routes>
            <Route exact path="/service" element={<Service />} />
            <Route exact path="/service/:id" element={<Service />} />
            <Route exact path="/country-guide" element={<CountryGuide />} />
            <Route exact path="/contact-us" element={<ContactUs />} />
            <Route exact path="/get-quote" element={<GetAQuote />} />
            <Route exact path="/gallery" element={<Gallery />} />
            <Route exact path="/services" element={<Services />} />
            <Route exact path="/about" element={<About />} />
            <Route exact path="/home" element={<HomePage />} />
            <Route exact path="/" element={<HomePage />} />
            <Route exact path="/privacy-policy" element={<Privacy />} />
            <Route exact path="/load-calculator" element={<LoadCalculator />} />
          </Routes>
          <WhatsappIcon />
        </Router>
      </div>
    </>
  );
}

export default App;
