import React from "react";
import { Dialog, Transition } from '@headlessui/react'
import { Fragment } from 'react'

const ModelBox = ({ isOpen, setIsOpen }) => {


  function closeModal() {
    setIsOpen(false)
  }


  return (
    <>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-[500]" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">

              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-max transform overflow-hidden rounded-2xl bg-white sm:p-5 p-4 pt-5  text-left align-middle shadow-xl transition-all relative">
                  <Dialog.Title
                    as="h3"
                    className="sm:text-[32px] text-[28px] leading-9 font-medium text-[#002A39]  shrink-0 w-full font-vetica"
                  >
                    Form Successfully Submitted
                  </Dialog.Title>
                  <svg width="16" height="16" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" className="absolute right-5 top-5" onClick={closeModal}>
                    <path d="M21 21L2 2M21 2L2 21" stroke="#41BDE9" stroke-width="3" stroke-linecap="round" />
                  </svg>
                  <div className="mt-2">
                    <p className="text-sm text-[#03719A] w-[80%] sm:leading-[22px] leading-4">
                      Thank you for your interest in our services! We’ll get back to you ASAP!
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border border-transparent bg-blue-100 px-3 py-2 text-[18px] font-vetica font-medium text-[#197698] hover:bg-blue-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Got it, thanks
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}


export default ModelBox;
