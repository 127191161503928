import React, { useEffect } from "react";
import Header from "../Components/Header";
import Topbar from "../Components/Topbar";
import TwoColumOne from "../Components/TwoColumOne";
import Footer from "../Components/Footer";
import FooterBanner from "../Components/FooterBanner";
import efs_logo from "../assets/logo/efs_logo.png";
import headPic from "../assets/images/misc/COMPANY.png";

import abtout_us_img from "../assets/images/about_us/Rectangle 11.png";

import years_img from "../assets/images/about_us/Group 1053.png";
import industry_img from "../assets/images/about_us/Group 1054.png";
import packing_img from "../assets/images/about_us/Group 1056.png";
import care_img from "../assets/images/about_us/Group 1055.png";
import icon_vision from "../assets/images/icons/Group 1048.png";
import icon_mission from "../assets/images/icons/Group 1050.png";
import icon from "../assets/images/icons/Group.svg";
import { Link } from "react-router-dom";

function About({ type, name, see_all_btn }) {
  const heading01 = "More than just a";
  const heading02 = "Moving company";
  const desc =
    "Moving company that goes beyond simple relocation by ensuring quality services and reliability of our customers.";
  const button = {
    text: "Contact Us",
    link: "/contact-us",
  };

  const services = [
    {
      image: years_img,
      heading: "7 YEARS OF EXPERIENCE",
      description:
        "7 years of experience equates to thousands of hours perfecting our operational services, all you don't have worry about a thing",
    },
    {
      image: industry_img,
      heading: "PROVEN INDUSTRY EXPERTISE",
      description:
        "A team of dedicated professionals with the know-how of getting it there at maximum efficiency.",
    },
    {
      image: packing_img,
      heading: "PROFESSIONAL PACKING",
      description:
        "We have a team of proficient carpenters, experienced supervisors & survey team.",
    },
    {
      image: care_img,
      heading: "SPECIALIZED CARE",
      description:
        "Wheather it's a one-of-a-kind antique of the finest of glassware, our content handling procedure rates best in class and the reasons are clear.",
    },
  ];

  return (
    <div>
      <head>

      <title>About - CargoTrack | Relocation Experts in KSA</title>
      </head>
      {/* begin */}
      <div>
        <Header />
        <dir className="lg:mx-[100px] sm:mx-[30px] mx-[16px]"></dir>

        {/* home top */}

        <div className="lg:px-[100px] sm:px-[30px] px-[16px] w-full flex lg:flex-row flex-col hight900:h-[800px] hightUt900:h-screen h-full items-center justify-center gap-4 lg:pt-40  pt-20 ">
          <div className="lg:pt-0 pt-10 gap-5">
            <div className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] shrink-0 w-max leading-[32px]">
              {heading01}{" "}
            </div>
            <div className="font-vetica xl:text-5xl lg:text-5xl lg:leading-5xl text-[36px] text-[#00AEEF] shrink-0 w-max leading-[40px] pb-3">
              {heading02}
            </div>
            <div className="banner_font color-primary-medium"></div>
            <div className="color-primary-medium banner_font_medium  mb-4 max-w-[600px] leading-6">
              {desc}
            </div>
            {button && (
              <Link
                to={button.link}
                className="font-vetica cursor-pointer bg-[#00AEEF] lg:w-[163px] lg:h-[58px] w-[88px] h-[36px] items-center justify-center flex  lg:rounded-[9px] rounded lg:text-[26px] text-[14px] text-white font-medium hover:bg-[#03719A] duration-300"
              >
                {button.text}
              </Link>
            )}

            {/* download browser */}
            <a href="https://drive.google.com/uc?export=download&id=1dJAK3G7IYwhhvXTsyCdCsofbiIF35Dza">
              <div className="flex flex-row items-center justify-start gap-3  shrink-0 mt-4">
                <a className="relative text-[#00AEEF] text-[22px] cursor-pointer">
                  Download e-brochure
                  <span
                    className="absolute w-full left-0 bg-[#00AEEF] opacity-50 bottom-0.5"
                    style={{ height: "2px" }}
                  />
                </a>
                <span className="grid place-content-center bg-[#00AEEF] h-[30px] w-[30px] rounded shrink-0">
                  <svg
                    width="10"
                    height="15"
                    viewBox="0 0 10 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 14.9991H0V13.5774H10V14.9991ZM5 12.1558L0.714286 7.89089L1.72143 6.88864L4.28571 9.43337V0.782715H5.71429V9.43337L8.27857 6.88864L9.28571 7.89089L5 12.1558Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>
            </a>
          </div>

          <div className="w-full h-full flex items-center lg:justify-end justify-center">
            <img src={headPic} alt="" className="object-cover w-min h-full" />
          </div>
        </div>

        {/* home to ends */}

        <div className="xsection row p-0 align-items-stretch dark topmargin-sm ">
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "5%",
              marginTop: "5%",
            }}
          >
            <div
              className="xquestion_banner rounded-[21px] overflow-hidden"
              style={{
                height: "auto",
                width: "80%",
                backgroundPosition: "center",
              }}
            >
              <div
                style={{
                  height: "500px",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundImage: `url("${abtout_us_img}")`,
                  display: "flex",
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  flexDirection: "row",
                }}
                className=""
              >
                <div
                  className="bg-primary-light min-h-[130px]"
                  style={{
                    flex: 1,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    borderRadius: "0px 0px 20px 20px",
                  }}
                >
                  <div
                    className=" about_us_content lg:gap-5 gap-3 lg:w-[80%] mt-3 p-3 flex lg:flex-row flex-col"
                    style={{
                      alignItems: "center",
                    }}
                  >
                    <div className="h-20 ">
                      <img src={efs_logo} className="object-contain h-full" />
                    </div>
                    <div
                      style={{ flex: 2 }}
                      className="text-[#07509D] lg:text-left text-center"
                    >
                      Cargotrack is the sister company of EFS Logistics company,
                      and specializes in moving personal effects
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="xsection row p-0 align-items-stretch dark topmargin-sm">
          <div
            className="col-lg-12"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              marginBottom: "5%",
            }}
          >
            <div
              className="about_us_content text-[#03719A]"
              style={{ textAlign: "center", width: "75%" }}
            >
              In 2017, Cargotrack born as a sister company of EFS Logistics.
              Cargo track company is dedicated to handle the personal effects
              and specialized Services like home relocation, office relocation,
              groupie service, packing, storage, road transport, vehicle
              transportation and mobility services. Now cargo track has become a
              major relocation company based at Jeddah and has it branches at
              Riyadh & Dammam.
            </div>
          </div>
        </div>

        {/* background-image: url(./images/ribbon-banners/Group 1002.png); */}

        <div className="section-about mt-0 p-3 w-full min-h-[300px] h-auto ">
          <div className="lg:px-[100px] sm:px-[30px] px-[16px]  py-[30px] grid grid-cols-2 place-content-center w-full">
            <div className="flex justify-center">
              <div className="feature-box fbox-outline fbox-dark fbox-effect clearfix ">
                <div className="fbox-content flex items-start justify-center flex-col lg:max-w-[400px]">
                  <div className="h-8 ">
                    <img src={icon_vision} className="object-contain h-full" />{" "}
                  </div>
                  <h5 className="text-white text-[50px] font-medium">Vision</h5>
                  <div style={{ fontSize: "16px" }} className="max-w-[70%]">
                    To establish ourself as market leaders by providing best
                    customised relocation services
                  </div>
                  <div className="d-block d-md-block d-lg-none bottommargin" />
                </div>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="feature-box fbox-outline fbox-dark fbox-effect clearfix">
                <div className="fbox-content flex items-start justify-center flex-col lg:max-w-[400px]">
                  <div className="h-8 ">
                    <img src={icon_mission} className="object-contain h-full" />
                  </div>
                  <h5 className="text-white text-[50px] font-medium">
                    Mission
                  </h5>
                  <div style={{ fontSize: "16px" }} className="max-w-[70%]">
                    To providing easy relocation service experience with
                    complete customer focus at affordable price
                  </div>
                  <div className="d-block d-md-block d-lg-none bottommargin" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <>
          {/* begin */}

          <section
            id="page-title"
            className="bg-transparent lg:mx-[100px] sm:mx-[30px] mx-[16px]"
          >
            <div
              className="container clearfix "
              style={{
                // display: 'flex',
                justifyContent: "space-between",
                alignItems: "center",
                // flexDirection: 'column',
              }}
            >
              <div className="banner_font h-10 flex gap-3 text-[#03719A] text-[36px] mb-4 font-vetica">
                <img src={icon} className="object-contain h-full" />
                Why{" "}
                <span className="text-[#00AEEF] shrink-0 flex">Choose Us</span>
              </div>
              <div className="text-[18px] lg:w-[50%] mt-3 text-[#03719A] text-left">
                We understand that finding a reliable logistics partner to take
                care of your business with so many options can be a challenge.
                Here are a few reasons that set us apart from the crowd.
              </div>

              {see_all_btn && (
                <button className="btn-see-all ">See all </button>
              )}
            </div>
          </section>
          <section id="content" className="lg:px-[100px] sm:px-[30px] px-[16px] pb-10">
            <div className="content-wrap p-[0]">
              <div className="container clearfix">
                <div className="lg:grid grid-cols-2 w-full flex flex-col gap-5">
                  {services.map((service, index) => {
                    return <ServiceCard service={service} />;
                  })}
                </div>
              </div>
            </div>
          </section>

          {/* end */}
        </>

        <FooterBanner />
        <Footer />
      </div>
      {/* end */}
    </div>
  );
}

export default About;

const ServiceCard = ({ service }) => {
  return (
    <>
      <div>
        <div className="lg:grid hidden grid-cols-2 w-full place-items-start">
          <div className="">
            <img
              src={service.image}
              className="object-contain h-full"
              alt="image"
            />
          </div>
          <div className="">
            <div className="xteam-title p-4">
              <span className="service-heading text-[#03719A]">
                {service.heading}
              </span>
              <br />
              <span className="text-[22px] leading-[26px] text-[#002A39]">
                {service.description}
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* mobile */}

      <div className="flex lg:hidden flex-col">
        <span className="font-medium text-[26px] leading-[26px] text-[#00AEEF] mb-3">
          {service.heading}
        </span>
        <div className="w-full flex items-center justify-center">
          <div className="w-[40%] shrink-0 mr-3">
            <img
              src={service.image}
              className="object-contain w-full"
              alt="image"
            />
          </div>
          <span className="text-lg text-[#03719A]">{service.description}</span>
        </div>
      </div>
    </>
  );
};
