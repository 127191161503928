import React, { Fragment, useRef, useState } from "react";
import Header from "../Components/Header";
import Topbar from "../Components/Topbar";
import TwoColumOne from "../Components/TwoColumOne";
import Footer from "../Components/Footer";
import FooterBanner from "../Components/FooterBanner";
import fb from "../assets/images/icons/Group 965 (2).png";
import insta from "../assets/images/icons/Group 964 (1).png";
// import utube from "../assets/images/icons/utub963.png";
import whatsapp from "../assets/images/icons/Group 966 (1).png";
import linkedin from "../assets/images/icons/Group 962 (1).png";
import youtube from "../assets/images/icons/Group 963 (1).png";
import { Link } from "react-router-dom";
import { serviceslist } from "./Services";
import emailjs from "@emailjs/browser";
import { Dialog, Transition } from "@headlessui/react";
import ModelBox from "../Components/ModelBox";

import dammamImage from "../assets/images/misc/Dammam.webp";
import jeddahImage from "../assets/images/misc/Jeddah.webp";
import riyadhImage from "../assets/images/misc/Riyadh.webp";

const customerFeedback = [
  {
    country: "Jeddah",
    address: "King Khalid Road, Al-Baghaduya AL Sharqiyah",
    mobile: "+966 59 096 7593",
    image: jeddahImage,
  },
  {
    country: "Riyadh",
    address: "Hanin Ibh Ishaq Street, Al Futhra, Riyadh 12632",
    mobile: "+966 58297 1155",
    image: riyadhImage,
  },
  {
    country: "Dammam",
    address: "Al Amir Muhammed Ibn Fahad Road, Ar Rabi, Dammam 32241-56900",
    mobile: "+966 58182 9256",
    image: dammamImage,
  },
];

const socialIcon = [
  { icon: whatsapp, url: "https://wa.me/966569197864?text=Hi%20There!%20" },
  { icon: fb, url: "https://www.facebook.com/cargotrackco?mibextid=LQQJ4d" },
  {
    icon: insta,
    url: "https://instagram.com/cargotrack.sa?igshid=YmMyMTA2M2Y=",
  },
  { icon: linkedin, url: "https://www.linkedin.com/company/cargotrackmovers/" },
];
function ContactUs({ type, name, see_all_btn }) {
  let [isOpen, setIsOpen] = useState(false);
  const formref = useRef();
  const [formdata, setformdata] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
    moving_from_city: "",
    moving_from_country: "",
    moving_to_city: "",
    moving_to_country: "",
    moving_date: "",
    moving_reason: "",
    who_is_moving_adults: "",
    who_is_moving_kids: "",
    what_are_you_moving: "",
    why_are_you_moving: "",
    pickup_date: "",
    callback_time: "",
    first_name: "",
    last_name: "",
  });
  function closeModal() {
    setIsOpen(false);
  }

  const handleChange = (e) => {
    setformdata((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_bx9hiz8",
        "template_2jygg49",
        formref.current,
        "8LlEbwdzoBwdBdcbW"
      )
      .then(
        (result) => {
          console.log(result);
          setIsOpen(true);
          setformdata({
            name: "",
            phone: "",
            email: "",
            message: "",
            moving_from_city: "",
            moving_from_country: "",
            moving_to_city: "",
            moving_to_country: "",
            moving_date: "",
            moving_reason: "",
            who_is_moving_adults: "",
            who_is_moving_kids: "",
            what_are_you_moving: "",
            why_are_you_moving: "",
            pickup_date: "",
            callback_time: "",
            first_name: "",
            last_name: "",
          });
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const heading01 = "Contact Us";
  const heading02 = "";
  const desc = "Get in touch and let us know how we can help";
  // const button = ({
  //     text: 'Contact Us',
  //     link: '/country-guide'
  // })

  return (
    <div>
      <head>
        <title>Contact Us - CargoTrack | Relocation Experts in KSA</title>
      </head>
      {/* begin */}
      <div>
        <Topbar />
        <Header />

        {/* home top */}

        <div className="lg:px-[100px] sm:px-[30px] px-[16px] w-full flex lg:flex-row flex-col h-[900px] items-start justify-start gap-4 md:pt-40 pt-20 ">
          <div className="grid w-full">
            <div className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] shrink-0 w-max leading-[32px]">
              {heading01}{" "}
            </div>
            <div className="banner_font color-primary-medium"></div>
            <div className="color-primary-medium banner_font_medium mb-3 mt-2 leading-5 max-w-[80%]">
              {desc}
            </div>
            <div className="flex flex-row gap-2 mb-4">
              {socialIcon.map((item) => (
                <a href={item.url} className="h-[35px] w-[35px]">
                  <img
                    src={item.icon}
                    className="p-1 footer_social_icons object-contain h-full"
                    alt="icon"
                  />
                </a>
              ))}
            </div>
            <form className="mb-2" ref={formref}>
              <div className="input-group mb-3 row">
                <div className="col-12 col-md-6 px-1 h-[46px]">
                  <input
                    type="text"
                    className="form-control-1 h-full px-3"
                    onChange={handleChange}
                    name={"first_name"}
                    value={formdata.first_name}
                    placeholder="First Name"
                    aria-label="First Name"
                  />
                </div>
                <div className="col-12 col-md-6 px-1 h-[46px]">
                  <input
                    type="text"
                    className="form-control-1 h-full px-3"
                    onChange={handleChange}
                    name={"last_name"}
                    value={formdata.last_name}
                    placeholder="Last Name"
                    aria-label="Last Name"
                  />
                </div>
              </div>
              {/* 
                  mx-[100px]          <div class="form-heading">Moving To (Destination)</div> */}
              <div className="input-group mb-3 row">
                <div className="col-12 col-md-6 px-1 h-[46px]">
                  <input
                    type="text"
                    className="form-control-1 h-full px-3 "
                    onChange={handleChange}
                    name={"email"}
                    value={formdata.email}
                    placeholder="E-mail"
                    aria-label="E-mail"
                  />
                </div>

                <div className="col-12 col-md-6 px-1 h-[46px]">
                  <input
                    type="text"
                    className="form-control-1 h-full px-3"
                    onChange={handleChange}
                    name={"phone"}
                    value={formdata.phone}
                    placeholder="Mobile Number"
                    aria-label="Mobile Number"
                  />
                </div>
              </div>

              <div className="input-group mb-3 row px-1">
                <div className="col-12 rounded-[5px]">
                  <textarea
                    rows={4}
                    className="form-control-1 px-3 pt-3"
                    onChange={handleChange}
                    name={"message"}
                    value={formdata.message}
                    placeholder="Tell us more about your"
                    aria-label="No.of Adults"
                    style={{ minWidth: "100%" }}
                  />
                </div>
              </div>
            </form>
            <button
              onClick={handleSubmit}
              className="color-primary-medium banner_font_medicursor-pointer bg-[#002A39] px-3 py-2 rounded-[9px] text-[26px] text-white hover:bg-[#002A39] duration-300  w-max"
            >
              Submit
            </button>
          </div>

          {/* image */}

          <div
            className="w-full h-full"
            style={{
              // height: '70vh',
              background: `url("images/misc/CONTACT US.png") center center no-repeat`,
              backgroundSize: "contain",
              backgroundPosition: "top",
            }}
          />
        </div>

        {/* modelBox  */}

        <ModelBox isOpen={isOpen} setIsOpen={setIsOpen} />

        {/* home to ends */}

        <section id="content">
          <div className="content-wrap">
            <div className="container clearfix w-full flex items-center justify-center">
              <div className="xl:w-[80%] flex flex-row  flex-wrap gap-3 items-center justify-center">
                {customerFeedback.map((item, index) => (
                  <AddressCard key={index} item={item} />
                ))}
              </div>
            </div>
          </div>
        </section>

        <FooterBanner />
        <Footer />
      </div>
      {/* end */}
    </div>
  );
}

export default ContactUs;

const AddressCard = ({ item }) => {
  return (
    <div className="w-full max-w-[300px] bg-[#002A39] rounded-[14px] flex items-center justify-start flex-col overflow-hidden pb-4 ">
      <span
        className="z-10 relative flex items-end justify-center after:absolute after:w-full  after:z-10 after:bg-gradient-to-t after:from-[#002A39] after:to-transparent after:h-[50%]"
      >
        <img src={item.image} className="object-cover w-full -z-10 relative" />
      </span>
      <div className="font-medium w-full flex items-center justify-center text-[32px] text-white">
        {item.country}
      </div>

      <div className="flex content-center py-2 text-[20px] font-medium md:w-full w-[80%] text-[#00AEEF] text-center leading-8 h-[120px] px-1">
        {item.address}
      </div>
      <span className="text-[21px] text-white font-medium">
        Mobile : {item.mobile}
      </span>
    </div>
  );
};
