import React, { Fragment, useRef, useState } from "react";
import Header from "../Components/Header";
import Topbar from "../Components/Topbar";
import Footer from "../Components/Footer";
import FooterBanner from "../Components/FooterBanner";
import emailjs from "@emailjs/browser";
import ModelBox from "../Components/ModelBox";

const initialState = {
  first_name: "",
  last_name: "",
  phone: "",
  email: "",
  message: "",
  name:'',
  moving_from_city:'',
  moving_to_city:'',
  moving_from_country:'',
  moving_to_country:'',
  who_is_moving_adults:'',
  who_is_moving_kids:'',
  why_are_you_moving:'',
  what_are_you_moving:'',
  pickup_date:'',
  callback_time:'',

};

function GetAQuote({ type, name, see_all_btn }) {
  let [isOpen, setIsOpen] = useState(false);
  const formref = useRef();
  const [formdata, setformdata] = useState(initialState);
  const heading01 = "More than just a";
  const heading02 = "Moving";
  const desc =
    "Moving company that goes beyond simple relocation by ensuring quality services and reliability of our customers.";
  const button = {
    text: "Contact Us",
    link: "/country-guide",
  };

  const handleChange = (e) => {
    setformdata((prev) => ({ ...prev, [e.target.name]: e.target.value }));
    console.log(formref.current);
  };
  function closeModal() {
    setIsOpen(false);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_bx9hiz8",
        "template_2uclbms",
        formref.current,
        "8LlEbwdzoBwdBdcbW",
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsOpen(true);
          setformdata(initialState);
          // setModalData(prev => ({ ...prev, isopen: true, title: 'Thank You! We’ll be in touch soon!', content: 'We’ve received your request for a quote. You will receive a response from our executive shortly.  ', buttonName: 'OK' }))
        },
        (error) => {
          console.log(error.text);
        },
      );
  };

  return (
    <div>
      <head>
            <title>
            Get A Quote - CargoTrack | Relocation Experts in KSA
            </title>
        </head>
      {/* begin */}
      <div>
        <Topbar />
        <Header />

        {/* home top */}

        <div className="lg:px-[100px] sm:px-[30px] px-[16px] w-full flex lg:flex-row flex-col lg:h-[600px] h-full items-center justify-start gap-4 md:pt-40 pt-20 ">
          <div className="flex items- justify-center flex-col w-full">
            <div className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#03719A] shrink-0 w-max leading-[32px]">
              Get a{" "}
              <span className="font-vetica xl:text-5xl lg:text-5xl text-[36px] lg:leading-[60px] text-[#00AEEF] shrink-0 w-max leading-[32px]">
                Quote
              </span>
            </div>

            {/* forms */}

            <div class="form-heading sm:text-[#002A39] text-[#00AEEF]  mt-11">
              Personal Details
            </div>
            <div className="grid gap-2 mt-2 lg:w-[350px]">
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control-1 h-[46px] px-3"
                  onChange={handleChange}
                  name={"name"}
                  value={formdata.name}
                  placeholder="Name"
                  aria-label="First Name"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  type="text"
                  className="form-control-1 h-[46px] px-3"
                  onChange={handleChange}
                  value={formdata.last_name}
                  name={"last_name"}
                  placeholder="Last Name"
                  aria-label="Last Name"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  type="email"
                  className="form-control-1 h-[46px] px-3"
                  onChange={handleChange}
                  name={"email"}
                  value={formdata.email}
                  placeholder="E-Mail"
                  aria-label="E-Mail"
                  aria-describedby="basic-addon1"
                />
              </div>
              <div className="input-group mb-2">
                <input
                  type="number"
                  className="form-control-1 h-[46px] px-3"
                  onChange={handleChange}
                  name={"phone"}
                  value={formdata.phone}
                  placeholder="Mobile Number"
                  aria-label="Mobile Number"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>

            {/* forms ends*/}
          </div>

          {/* image */}

          <div
            className="w-full h-full lg:block hidden"
            style={{
              // height: '70vh',
              background: `url("images/misc/CONTACT US.png") center center no-repeat`,
              backgroundSize: "contain",
              backgroundPosition: "top",
            }}
          />
        </div>

        <form
          className="sm:px-[30px] px-[16px] lg:px-[100px] grid lg:grid-flow-col gap-5 lg:w-max  lg:mt-0 mt-4"
          ref={formref}
        >
          <div className="flex flex-col ">
            <div class="form-heading sm:text-[#002A39] text-[#00AEEF] ">
              Moving From (Origin)
              <div className="grid sm:grid-cols-2 gap-4 mt-2">
                <input
                  type="text"
                  className="form-control-1 px-3 h-[46px] lg:w-[350px] w-full"
                  onChange={handleChange}
                  name={"moving_from_city"}
                  value={formdata.moving_from_city}
                  placeholder="Country/Territory"
                  aria-label="Country/Territory"
                />
                <input
                  type="text"
                  className="form-control-1 px-3 h-[46px] lg:w-[350px]"
                  onChange={handleChange}
                  name={"moving_from_country"}
                  value={formdata.moving_from_country}
                  placeholder="City/Town/Localityy"
                  aria-label="City/Town/Locality"
                />
              </div>
            </div>

            <div class="form-heading sm:text-[#002A39] text-[#00AEEF] mt-3">
              Moving To (Destination)
              <div className="grid sm:grid-cols-2 gap-4 mt-2 ">
                <input
                  type="text"
                  className="form-control-1 px-3 h-[46px] lg:w-[350px] "
                  onChange={handleChange}
                  name={"moving_to_city"}
                  value={formdata.moving_to_city}
                  placeholder="Country/Territory"
                  aria-label="Country/Territory"
                />
                <input
                  type="text"
                  className="form-control-1 px-3 h-[46px] lg:w-[350px]"
                  onChange={handleChange}
                  name={"moving_to_country"}
                  value={formdata.moving_to_country}
                  placeholder="City/Town/Locality"
                  aria-label="City/Town/Locality"
                />
              </div>
            </div>

            <div class="form-heading sm:text-[#002A39] text-[#00AEEF]  mt-5">
              Who is Moving?
              <div className="grid sm:grid-cols-2 gap-4 mt-2 ">
                <input
                  type="text"
                  className="form-control-1 px-3 h-[46px] lg:w-[350px] "
                  onChange={handleChange}
                  name={"who_is_moving_adults"}
                  value={formdata.who_is_moving_adults}
                  placeholder="No.of Adults"
                  aria-label="No.of Adults"
                />
                <input
                  type="text"
                  className="form-control-1 px-3 h-[46px] lg:w-[350px]"
                  onChange={handleChange}
                  name={"who_is_moving_kids"}
                  value={formdata.who_is_moving_kids}
                  placeholder="No.of Kids"
                  aria-label="No.of Kids"
                />
              </div>
            </div>

            <div className="grid sm:grid-cols-2 gap-4 mt-3 ">
              <div class="form-heading sm:text-[#002A39] text-[#00AEEF] ">
                Why are you Moving?
                <div className="grid gap-4 mt-2 h-[46px]">
                  <input
                    type="text"
                    className="form-control-1 px-3 h-[46px] lg:w-[350px] "
                    onChange={handleChange}
                    name={"why_are_you_moving"}
                    value={formdata.why_are_you_moving}
                    placeholder="eg: Family Reasons"
                    aria-label="eg: Family Reasons"
                  />
                </div>
              </div>

              <div class="form-heading sm:text-[#002A39] text-[#00AEEF] ">
                What are you Moving?
                <div className="grid gap-4 mt-2 h-[46px]">
                  <input
                    type="text"
                    className="form-control-1 px-3 h-[46px] lg:w-[350px]"
                    onChange={handleChange}
                    name={"what_are_you_moving"}
                    value={formdata.what_are_you_moving}
                    placeholder="eg: 1-2 Bedroom Home"
                    aria-label="eg: 1-2 Bedroom Home"
                  />
                </div>
              </div>
            </div>

            <div class="form-heading sm:text-[#002A39] text-[#00AEEF]  mt-3">
              Pick up Date
              <div className="grid sm:grid-cols-2 gap-4 mt-2 ">
                <input
                  type="date"
                  className="form-control-1 px-3 h-[46px] lg:w-[350px]"
                  onChange={handleChange}
                  name={"pickup_date"}
                  value={formdata.pickup_date}
                  placeholder="Pick a Date"
                  aria-label="Pick a Date"
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>

            <div class="form-heading sm:text-[#002A39] text-[#00AEEF] mt-5">
              Convenient time to call back?
              <div className="grid sm:grid-cols-2 gap-4 mt-2 ">
                <input
                  type="time"
                  className="form-control-1 px-3 h-[46px] lg:w-[350px]"
                  onChange={handleChange}
                  name={"callback_time"}
                  value={formdata.callback_time}
                  placeholder="Select a time slot "
                  aria-label="Select a time slot "
                  aria-describedby="basic-addon1"
                />
              </div>
            </div>

            <div className="input-group mb-2 mt-3 ">
              <button
                className="h-[58px] w-[179px] rounded-lg hover:bg-[#00455e] bg-[#002A39] text-white"
                onClick={handleSubmit}
                title={
                  !(formdata.email && formdata.phone) && "please enter details"
                }
                disabled={!formdata.email && !formdata.phone}
              >
                Get a Quote
              </button>
            </div>
          </div>
        </form>

        {/* modelBox  */}

        <ModelBox isOpen={isOpen} setIsOpen={setIsOpen} />

        {/* home to ends */}
        <FooterBanner />
        <Footer />
      </div>
      {/* end */}
    </div>
  );
}

export default GetAQuote;
