import React from "react";
import fiata from "../assets/images/ribbon-banners/Fédération_Internationale_des_Associations_de_Transitaires_et_Assimilés_(logo) 1.png";
import ima from "../assets/images/ribbon-banners/ima.jpeg";
import moversPoe from "../assets/images/ribbon-banners/movers poe.png";
import tagline from "../assets/images/ribbon-banners/IAM_wTag-W_blue_logo-lg-tagline 1.png";
import iata from "../assets/images/ribbon-banners/image 18.png";
import gen from "../assets/images/ribbon-banners/BOX.png";

function RibbonBanner() {
  return (
    <div
      className="xsection sm:mt-6 mt-3 xbg-primary-color-light sm:px-[30px] px-[16px] lg:px-[100px]"
      style={{ backgroundColor: "#b8dde9" }}
    >
      <div className="container clearfix">
        <div className="flex xl:items-center items-start gap-5 justify-between xl:flex-row flex-col w-full py-10">
          <div className="font-vetica text-[#00AEEF]  shrink-0 md:text-5xl text-4xl">
            Our Memberships & &nbsp;
            <br className="max-sm:hidden" />
            <span className="text-[#03719A] font-vetica">Affiliations</span>
          </div>
          <div className="flex items-center justify-center gap-3 flex-wrap">


          <img
              height={80}
              width={140}
              src={gen}
              alt=""
              className="object-contain h-[80px] w-auto "
            />


            <img
              height={80}
              width={140}
              src={tagline}
              alt=""
              className="object-contain h-[80px] w-auto "
            />
            <img
              height={80}
              width={140}
              src={iata}
              alt=""
              className="object-contain h-[80px] w-auto"
            />
            <img
              height={80}
              width={140}
              src={fiata}
              alt=""
              className="object-contain h-[80px] w-auto"
            />
            <img
              height={80}
              width={140}
              src={ima}
              alt=""
              className="object-contain  mix-blend-multiply h-[80px] w-auto"
            />
            <img
              height={80}
              width={140}
              src={moversPoe}
              alt=""
              className="object-contain h-[80px] w-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default RibbonBanner;
